import React from "react"
import * as Icon from "react-feather"
import CaseDetail from "../../components/Shared/casedetails"
import { graphql } from "gatsby"

const seoTitle = "Quality Engineering "
const pageTitle = "Quality Engineering"
const subTitle = "Total Quality Management"
const para =
  "To successfully manage quality within a Regulatory Reporting  platform , Atyeti has deployed a team at  its Global Financial services  client executing on its  ”Shift Left” testing strategy.  Atyeti’s team provides business domain expertise and defines the methods of validating numerous  changing regulations, which in turn  impact  the underlying processes used to create accurate  reports. There are numerous jurisdictions, multiple asset classes, and several reports,  increasing complexity of the platform."
const blockquote = `"Shift Left in the software development process leveraging Automation."`

const cardItems = [
  {
    id: 1,
    heading: `Deliverables`,
    listItems: (
      <>
        <li>
          <Icon.Square /> Build a high performing technology delivery team of
          QA/Domain SMEs and technologists to provide automation solutions.
        </li>
        <li>
          <Icon.Square />
          Define target operating model, target state, and phased approach for
          the program by adopting agile delivery.
        </li>
        <li>
          <Icon.Square />
          DevOps - Optimize program delivery with automation first approach,
          Front to Back environment blueprinting &amp; engineering, and
          effective dependency management.
        </li>
        <li>
          <Icon.Square />
          Build a metrics &amp; reporting program for delivery and
          transformation – use the data to make key decisions to achieve the
          target state.
        </li>
        <li>
          <Icon.Square /> Drive Shift-Left into App Dev teams.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Automation First`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Automation framework- agnostic of environment, application,
          regulation, jurisdiction and asset classes.
        </li>
        <li>
          <Icon.Square />
          Cross leveraging of the automation platform and frameworks to drive
          efficiency that results in effort reduction to implement and adopt
          automation.
        </li>
        <li>
          <Icon.Square />
          Zero retrospective automation and Continuous automated regression test
          execution of completed features.
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Core Methodologies`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          TDD
        </li>
        <li>
          <Icon.Square />
          BDD
        </li>
        <li>
          <Icon.Square />
          Agile
        </li>
        <li>
          <Icon.Square />
          Lean QA
        </li>
        <li>
          <Icon.Square />
          Automation as a configuration
        </li>
        <li>
          <Icon.Square />
          DSL- Domain Specific Language for Automation
        </li>
        <li>
          <Icon.Square />
          Automation Framework as a Service
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Zero PROD issues for covered business scenarios.
        </li>
        <li>
          <Icon.Square />
          Zero/Minimum defect leakage into to UAT phase.
        </li>
        <li>
          <Icon.Square />
          Reutilization of Test Artifacts &amp; Framework.
        </li>
        <li>
          <Icon.Square />
          Cross leveraging team expertise across different projects and build
          flexibility to address peaks across projects.
        </li>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query QualityEngineeringQuery {
    casedetailsJson(slug: { eq: "quality-engineering" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
